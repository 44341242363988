import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35')
];

export const server_loads = [0,2,3,12,4,5,10];

export const dictionary = {
		"/(root)": [17,[2]],
		"/(root)/analytics": [18,[2,3]],
		"/calls": [32,[11]],
		"/forms/devices": [33,[12]],
		"/forms/devices/[device_id]": [34,[12]],
		"/forms/driver-installs/[driverinstall_id]": [35,[12]],
		"/(root)/joessecretcorner": [19,[2,4]],
		"/(root)/locations": [20,[2,5]],
		"/(root)/locations/[facilityId]/[[unitId]]/[[roomId]]/campaigns/[...campaignParams]": [~21,[2,5,6,8],[,,7]],
		"/(root)/locations/[facilityId]/[[unitId]]/[[roomId]]/contacts": [~22,[2,5,6],[,,7]],
		"/(root)/locations/[facilityId]/[[unitId]]/[[roomId]]/devices/[...devicesParams]": [23,[2,5,6,9],[,,7]],
		"/(root)/locations/[facilityId]/[[unitId]]/[[roomId]]/polls": [24,[2,5,6],[,,7]],
		"/(root)/locations/[facilityId]/[[unitId]]/[[roomId]]/voice-apps": [~25,[2,5,6],[,,7]],
		"/(extra)/login": [~13],
		"/(extra)/logout": [~14],
		"/(extra)/oauth2/callback": [~15],
		"/(extra)/session-expired": [16],
		"/(root)/sessiondebug": [26,[2]],
		"/(root)/users": [27,[2,10]],
		"/(root)/users/roles": [~28,[2,10]],
		"/(root)/users/session-policies": [~29,[2,10]],
		"/(root)/webrtc": [30,[2]],
		"/(root)/webrtc/debug": [~31,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.js';