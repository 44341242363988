if (
	'trustedTypes' in window &&
	window.trustedTypes !== null &&
	typeof window.trustedTypes === 'object' &&
	'createPolicy' in window.trustedTypes &&
	typeof window.trustedTypes.createPolicy === 'function'
) {
	window.trustedTypes.createPolicy('default', {
		createHTML: (input: string): TrustedHTML => input as unknown as TrustedHTML,
		createScript: (input: string): TrustedScript => input as unknown as TrustedScript,
		createScriptURL: (input: string): TrustedScriptURL => input as unknown as TrustedScriptURL,
	});
}
